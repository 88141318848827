<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Modèles économiques"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="modeleEconomiques"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier un modèle économique'"
              v-b-modal.modal-modify
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="modeleEconomiques.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <b-modal
      id="modal-modify"
      ref="myModal"
      no-close-on-backdrop
      centered
      button-size="sm"
      modal-class="modal-warning"
      title="Modifier un modèle économique"
      :busy="modeleEconomiqueProcess"
      ok-title="Modifier"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="warning"
      size="sm"
      @ok="handleUpdateOk"
    >
      <validation-observer ref="modeleEconomiqueCreationRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Libellé"
                label-for="title"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="libellé"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="form.title"
                    :formatter="formatter"
                    placeholder="Libellé"
                    readonly
                    size="sm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Valeur du modèle économique"
                label-for="amount_value"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="valeur du modèle économique"
                  rules="required"
                >
                  <b-form-input
                    id="amount_value"
                    v-model="form.amount_value"
                    type="number"
                    placeholder="Valeur du modèle économique"
                    size="sm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Nombre de jours gratuits"
                label-for="days_number"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nombre de jours gratuits"
                  rules="required"
                >
                  <b-form-input
                    id="days_number"
                    v-model="form.days_number"
                    type="number"
                    placeholder="Nombre de jours gratuits"
                    size="sm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Valeur du modèle économique"
                label-for="name"
              >  <validation-provider
                #default="{ errors }"
                name="Valeur économique"
                rules="required"
              >
                <div class="d-flex">

                  <b-form-radio
                    v-model="form.value_type"
                    plain
                    name="some-radios3"
                    value="Montant fixe"
                    class="mr-4"
                  >
                    Montant Fixe
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.value_type"
                    plain
                    :disabled="form.title==='Commission'"
                    name="some-radios3"
                    value="Pourcentage"
                  >
                    Poucentage
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal, BFormRadio,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useModeleEconomiques from '@/composables/modeleEconomiqueService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []
    const {
      getAllModeleEconomiques, modeleEconomiques, updateModeleEconomique, loader, modeleEconomiqueProcess, modeleEconomiqueSuccess,
    } = useModeleEconomiques()

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'title', label: 'Libellé', sortable: true },
      { key: 'value_type', label: 'Type', sortable: true },
      { key: 'amount_value', label: 'Valeur', sortable: true },
      { key: 'days_number', label: 'Jours gratuits', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      title: '',
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    // Reset Form
    const resetForm = () => {
      form.id = null
      form.title = ''
    }

    const updatingModeleEconomique = async () => {
      await updateModeleEconomique({ ...form })
      await getAllModeleEconomiques()
    }
    const getItem = async item => {
      form.id = item.id
      form.title = item.title
      form.amount_value = item.amount_value
      form.days_number = item.days_number
      form.value_type = item.value_type
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    onMounted(async () => {
      await getAllModeleEconomiques()
      // Set the initial number of items
      totalRows.value = modeleEconomiques.value.length
    })
    // Variable ref de validation du modal
    const modeleEconomiqueCreationRules = ref('')
    // Variable ref du modal
    const myModal = ref('')

    const handleSubmitModal = () => {
      modeleEconomiqueCreationRules.value.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          if (form.id === null) ''
          else await updatingModeleEconomique({ ...form })
          if (modeleEconomiqueSuccess.value) myModal.value.toggle('#toggle-btn')
        }
      })
    }

    //  Fonction de soumission du modal
    const handleUpdateOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }


    return {

      getItem,
      sortOptions,
      updatingModeleEconomique,
      loader,
      modeleEconomiqueProcess,
      modeleEconomiqueSuccess,
      modeleEconomiques,

      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      handleUpdateOk,
      modeleEconomiqueCreationRules,
      myModal,

    }
  },

}
</script>
